const videos = Array.from(document.querySelectorAll('.js-video'));

function observerCallback(entries) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
        	entry.target.play();
        }
    });
}

window.addEventListener(
    'load',
    () => {
        const observer = new IntersectionObserver(observerCallback, {
            threshold: 0.1
        });

        videos.forEach((video) => observer.observe(video));
    },
    false
);