const colorSelect = document.querySelector('select[name="variantColor"]');
const sizeSelect = document.querySelector('select[name="variantSize"]');
const variantSelect = document.querySelector('select[name="purchasableId"]');
        
if (colorSelect) {
	colorSelect.addEventListener('change', () => {
        updateSelectStatus('color');
    });
}

if (sizeSelect) {
    sizeSelect.addEventListener('change', () => {
        updateSelectStatus('size');
    });
}

if (variantSelect) {
    // if (variantSelect.selectedIndex) {
    //     updateQty(variantSelect.options[variantSelect.selectedIndex]);
    // }
    variantSelect.addEventListener('change', () => {
        updatePrice(variantSelect.options[variantSelect.selectedIndex]);
        updateQty(variantSelect.options[variantSelect.selectedIndex]);
    });
}

// disableSoldOutOptions();
        
        
const updateSelectStatus = (attribute) => {

    if (attribute === 'color') {
        disableUnavailableOptions('color', colorSelect.options[colorSelect.selectedIndex].value);
    } else if (attribute === 'size') {
        disableUnavailableOptions('size', sizeSelect.options[sizeSelect.selectedIndex].value);
    }

   	selectVariant(colorSelect.options[colorSelect.selectedIndex].value, sizeSelect.options[sizeSelect.selectedIndex].value);
}

const selectVariant = (color, size) => {

    const selected = Array.from(variantSelect.options).filter((opt) => opt.dataset.color === color && opt.dataset.size === size)[0];

    if (selected) {
    	variantSelect.value = selected.value;
		variantSelect.options[variantSelect.selectedIndex].defaultSelected = true;

		updatePrice(selected);
		updateQty(selected);
	}  
}
        

const disableUnavailableOptions = (attribute, val) => {
    
    const reverseAttribute = attribute === 'color' ? 'size' : 'color';
    const otherSelect = attribute === 'color' ? sizeSelect : colorSelect;

    if (val !== '') {
        
        const available = [];

        Array.from(variantSelect.options).map((opt) => {
        	if (opt.dataset[attribute] === val && opt.disabled === false) {
        		available.push(opt.dataset[reverseAttribute]);
        		console.log(available);
        	}
        });

        Array.from(otherSelect.options).map((opt) => {
        	if (available.includes(opt.value) || opt.value === '') {
        		opt.disabled = false;
        	} else {
        		opt.disabled = true;
        	}
        });
    }

    disableSoldOutOptions();
}

const disableSoldOutOptions = () => {

    if (colorSelect) {
    	Array.from(colorSelect.options).map((opt) => {
        	let found = false;

        	Array.from(variantSelect.options).map((i) => {
        		if (i.dataset.outOfStock !== true && i.dataset.color === opt.value ) {
        			found = true;
        		}

        	});

        	if (!found) {
        		opt.disabled = true;
        	}

        });
    }

    if (sizeSelect) {
    	Array.from(sizeSelect.options).map((opt) => {
        	let found = false;

        	Array.from(variantSelect.options).map((i) => {
        		if (i.dataset.outOfStock !== true && i.dataset.size === opt.value ) {
        			found = true;
        		}

        	});

        	if (!found) {
        		opt.disabled = true;
        	}

        });
    }
}
        

const updatePrice = (option) => {
	const defaultPrices = document.querySelector('.js-default-price');
    const price = document.querySelector('.js-price');
    const salePrice = document.querySelector('.js-sales-price');

    if (option.dataset.price) {
    	price.innerText = option.dataset.price;
    	defaultPrices.classList.add('hidden');

    	if (option.dataset.price !== option.dataset.salePrice) {
    		price.classList.add('text-black-grey', 'line-through');
    		salePrice.innerText = option.dataset.salePrice;
    	}
    } else {
    	defaultPrices.classList.remove('hidden');
    	price.innerText = '';
    	salePrice.innerText = '';
    }
}

const updateQty = (option) => {
    const quantity = document.querySelector('.js-qty');

    if (option.dataset.stock) {
    	const max = option.dataset.stock < 10 ? option.dataset.stock : 10;

        quantity.length = 0;

    	[...Array(max)].forEach((_, i) => {
  			const opt = document.createElement('option');
    		opt.value = i+1;
    		opt.innerHTML = i+1;
    		quantity.appendChild(opt);
		});

    	quantity.classList.remove('hidden');

    
    } else {
    	quantity.classList.add('hidden');
    }
}