import Alpine from 'alpinejs';

const modal = document.querySelector('.js-waitlist');

if (modal && modal.querySelector('form')) {
	const form = modal.querySelector('form');
	const waitlist = modal.querySelector('select');
	const hiddenInput = form.querySelector('input[name="fields[productions]"]');

	let production = '';

	Alpine.store('waitlist', {
		open: false,

		toggle(value, label) {
			this.open = !this.open;

			if (waitlist.selectedIndex >= 0) {
				waitlist.options[waitlist.selectedIndex].defaultSelected = false;
			}

			if (value) {
				if (Array.from(waitlist.options).map((opt) => opt.value).includes(value)) {

					waitlist.value = value;
					waitlist.options[waitlist.selectedIndex].defaultSelected = true;

					production = waitlist.options[waitlist.selectedIndex].text;
					

				} else {
					if (label) {
						const opt = document.createElement('option');
						opt.value = value;
						opt.innerHTML = label;
						waitlist.appendChild(opt);

						waitlist.value = value;
						waitlist.options[0].defaultSelected = true;

						waitlist.classList.add('!p-0', 'pointer-events-none');
						waitlist.setAttribute('style', 'background:none!important');

						production = label;
					}
				}
			}

			hiddenInput.value = waitlist.value;
			waitlist.addEventListener('change', () => {
				hiddenInput.value = waitlist.value;
			})

		},

		clear() {
			document.querySelector('.js-waitlist header').classList.remove('hidden');
			form.style.display = 'block';

        	const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

        	// Fetch the new token and replace the CSRF input with  new one
        	fetch('/actions/formie/forms/refresh-tokens?form=joinWaitlist')
            	.then(result => { return result.json(); })
            	.then(result => { 
            		csrfInput.outerHTML = result.csrf.input; 

            		console.log(result);

            		if (result.captchas) {
                    	const captcha = result.captchas.javascript;
                    	const duplicateCaptcha = result.captchas.duplicate;

                    	if (captcha) {
                    		form.querySelector('input[name="' + captcha.sessionKey + '"]').value = captcha.value;
                    	}

                    	if (duplicateCaptcha) {
                    		form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                    	}
                	}
            	});

            	const message = document.querySelector('.fui-alert');
            	if (message) {
            		message.remove();
            	}

            	if (form.form && form.form.formTheme) {
                    form.form.formTheme.updateFormHash();
                }

                waitlist.classList.remove('hidden');
		}
	});

	form.addEventListener('onAfterFormieSubmit', () => {
		document.querySelector('.js-waitlist header').classList.add('hidden');
		waitlist.classList.add('hidden');

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'join_waitlist',
			production,
		});
	});
}
