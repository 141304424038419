import { scrollIntoView } from 'scroll-js';

const scrollItems = document.querySelectorAll('.js-smooth-scroll');
const scrollItemsSp = document.querySelectorAll('.js-smooth-scroll-sp');

Array.from(scrollItemsSp).forEach( el => {
    el.addEventListener('click', () => {
        const target = document.getElementById(el.dataset.target + 'mob');
        scrollIntoView(target, document.body, { behavior: 'smooth' });
    });
});

Array.from(scrollItems).forEach( el => {
    el.addEventListener('click', () => {
        const target = document.getElementById(el.dataset.target);

        if (target) {
            scrollIntoView(target, document.body, { behavior: 'smooth', top: '50' });
        }
        
        if (el.dataset.event && el.dataset.event === 'production_cta') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'production_cta',
              'CTA' : el.dataset.cta,
              'location' : el.dataset.location,
              'production': el.dataset.production,
            });
        }

        if (el.dataset.event && el.dataset.event === 'onpage_nav') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'onpage_nav',
              'title' : el.dataset.title,
              'selection' : el.dataset.selection
            });
        }

    });
});


const anchorInUrl = window.location.hash;
if (anchorInUrl) {
    const target = document.getElementById(anchorInUrl.substring(1));
    if(target) {
        setTimeout(() => {
            scrollIntoView(target, document.body, { behavior: 'smooth', top: '50' });
        }, 500)
    };
}