const htmlContent = document.documentElement.innerHTML;
const replacements = [
    { word: /©/gi, replacement: `<sup>©</sup>` },
	{ word: /™️/gi, replacement: `<sup>™️</sup>` },
	{ word: /®️/gi, replacement: `<sup>®️</sup>` },
];
const modifiedHTML = replacements.reduce((content, { word, replacement }) => {
  return content.replace(word, replacement);
}, htmlContent);

document.documentElement.innerHTML = modifiedHTML;