import { uniq } from 'lodash-es';

const lazycomponents = import.meta.glob('./**/index.js');
const handles = Array.from(document.querySelectorAll('[vue-app]')).map((node) => node.getAttribute('vue-app'));

uniq(handles).forEach((handle) => {
    const nodes = document.querySelectorAll(`[vue-app="${handle}"]`);

    if (nodes.length) {
        const key = `./${handle}/index.js`;

        lazycomponents[key]().then((module) => {
            module.default(nodes);
        });
    }
});
