import Alpine from 'alpinejs';

Alpine.store('docHeight', {
    bannerHeight: 0,

    setDocumentHeight() {
        const banner = document.querySelector('.js-banner');
        this.bannerHeight = banner ? banner.offsetHeight : 0;
        document.documentElement.style.setProperty('--doc-height', `${window.innerHeight - 80 - this.bannerHeight}px`);
    },

    removeOffset() {
        document.documentElement.style.setProperty('--doc-height', `${window.innerHeight - 80}px`);
    },

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.setDocumentHeight();
        });
    },
});