/* eslint-disable no-unused-vars */
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import 'swiper/css';

const carousels = Array.from(document.querySelectorAll('.swiper'));

carousels.forEach((carousel) => {
    const lightboxImages = carousel.querySelectorAll('.swiper-slide');

    if (lightboxImages.length) {

        new Swiper(carousel, {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            ...JSON.parse(carousel.dataset.config),
            pagination: {
                el: carousel.querySelector('[data-pagination]') || null
            },
            navigation: {
                nextEl: carousel.querySelector('[data-next]') || null,
                prevEl: carousel.querySelector('[data-prev]') || null
            }
        });

        const lightboxImages = carousel.querySelectorAll('[lightbox]');
        const lightbox = carousel.parentNode.querySelector('.swiper-lightbox');

        // Carousel lightbox
        if (lightboxImages.length > 0 && lightbox) {
            lightboxImages.forEach((el) => {
                el.addEventListener('click', () => {

                    new Swiper(lightbox, {
                        initialSlide: Number(el.dataset.swiperSlideIndex),
                        modules: [Navigation, Pagination],
                        ...JSON.parse(lightbox.dataset.config),
                        pagination: {
                            el: lightbox.querySelector('[data-pagination]') || null
                        },
                        navigation: {
                            nextEl: lightbox.querySelector('[data-next]') || null,
                            prevEl: lightbox.querySelector('[data-prev]') || null
                        }
                    });

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ 
                        'event': 'carousel_interaction',
                        'production' : el.dataset.production
                    });

                });
            });
        }
    }
});

const interactions = document.querySelectorAll('.js-carousel-interaction');
interactions.forEach((interaction) => {
    interaction.addEventListener('click', () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 
            'event': 'carousel_interaction',
            'production' : interaction.dataset.production.toLowerCase()
        });
    });
});
