import axios from 'axios';
import qs from 'qs';

const form = document.querySelector('.js-add-to-cart');

const submitForm = (data, form) => {
	return axios({
		method: 'POST',
		data,
		url: '/',
	})
	.then(function (response) {
		console.log(response.data);
		if (response.data.success && response.data.redirect) {
			window.location = response.data.redirect;
		} else if (response.data.success) {

			setTimeout(() => {
				// const button = form.querySelector('[type="submit"]');


				if(document.querySelector('.js-success')){
					const success = document.querySelector('.js-success');
					success.classList.remove('hidden');
				}


				// Update cart items number
				const quantity = form.querySelector('[name="qty"]').value;
				const carts = Array.from(document.querySelectorAll('.js-cart'));
				carts.forEach((cart) => {
					const items = Number(cart.innerHTML) + Number(quantity);
					cart.innerText = items;
				});


				const purchasableId = form.querySelector('[name="purchasableId"]').value;

				const ticketMobile = document.querySelector('.js-mobile-ticket');
				const cartMobile = document.querySelector('.js-mobile-cart');
				ticketMobile.classList.add('hidden');
				cartMobile.classList.remove('hidden');

				const dataset = { ...form.dataset };
				dataset.item_variant =  purchasableId;
				dataset.quantity = quantity;
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: 'add_to_cart',
					ecommerce: {
						items: [dataset]
					}
				});

			}, 1000)


		} else {
			const error = document.querySelector('.js-error');
			error.classList.remove('hidden');
		}
	});
}

if (form) {
	form.addEventListener('submit', (e) => {
        e.preventDefault();

        const data = {};
        const formData = new FormData(form);

		data.action = 'commerce/cart/update-cart',
		data[window.csrfTokenName] = window.csrfTokenValue;

		formData.forEach((value, key) => {
			data[key] = value;
		});

		// const button = form.querySelector('[type="submit"]');
		// button.innerText = 'Adding to cart';

		if (!window.session) {
			sessionStorage.setItem('data', qs.stringify(data));
			window.location = `/login?redirect=${window.location.href}&purchasableId=${data.purchasableId}&qty=${data.qty}`;

		} else {
			submitForm(qs.stringify(data), form);
		}
		const buttonAdded = form.querySelector('.added__btn');
		buttonAdded.classList.add('active');
		setTimeout(() => {
			buttonAdded.classList.remove('active')
		}, 3000)
    });

	const urlParams = new URLSearchParams(location.search);
	const data = sessionStorage.getItem('data');
    if (data && urlParams.has('purchasableId')) {
    	submitForm(data, form);
    	sessionStorage.removeItem('data');
    }
}

