let openTableBtn = document.querySelector('.open-sizetable');
let sizeTable = document.querySelector('.size-table');

if (openTableBtn) {
    openTableBtn.addEventListener('click', ((e) => {
        if (e.target.closest('.open-sizetable')) {
            sizeTable.classList.remove('hidden')
            sizeTable.classList.add('active')
        }
    }))
}
if (sizeTable) {
    sizeTable.querySelector('.size-table__close').addEventListener('click',((e) => {
        if (e.target.closest('.size-table__close') && sizeTable.classList.contains('active')) {
            sizeTable.classList.add('hidden')
            sizeTable.classList.remove('active')

        }
    }))
}


window.addEventListener('keydown', ((e) => {
    if(sizeTable) {
        if (sizeTable.classList.contains('active')) {
            if(e.key == `Escape`) {
                sizeTable.classList.add('hidden')
                sizeTable.classList.remove('active')
            }
        }
    }

}))
