import Alpine from 'alpinejs';

const body = document.querySelector('body');

Alpine.store('modal', {
    on: false,
    scrollPosition: '',
    storedValue: '',
    open: false,

    toggleBodyClass() {
    	body.classList.toggle('has-overlay');
    },

    saveScrollPosition() {
    	this.scrollPosition = `-${window.scrollY}px`;
        body.style.top = this.scrollPosition;
    },

    toggle() {
        if (!this.open) {
            this.saveScrollPosition();
        }

        this.open = !this.open;
        this.toggleBodyClass();
    },
});

Alpine.effect(() => {
	const store = Alpine.store('modal');

    if (!store.open) {
    	// Reset previous body position
    	body.style.top = '';
        window.scrollTo(0, parseInt(store.scrollPosition || '0') * -1);
    }
})
