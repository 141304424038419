import tailwindConfig from './tailwind';
const {
    theme: { screens }
} = tailwindConfig;

function tailwindToMedia(query) {
    const sizes = query.split(' ');

    const mediaQueries = sizes.map((size) => {
        const split = size.split(':');
        if (split.length === 1) return size;
        return `(min-width: ${screens[split[0]]}) ${split[1]}`;
    });

    return mediaQueries.join(',');
}

document.querySelectorAll('img[imgwind]').forEach((image) => {
    image.dataset.sizes = tailwindToMedia(image.dataset.sizes);
});

window.lazySizes.init();
