const items = document.querySelectorAll('.js-gtm');

Array.from(items).forEach( el => {
    el.addEventListener('click', (e) => {
    	e.preventDefault();

    	const url = el.href || null;
    	let dataset = { ...el.dataset };

    	dataset = Object.fromEntries(
  			Object.entries(dataset).map(([key, value]) => [key, value.toLowerCase()])
		);

    	window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(dataset);


		if (url) {
			const target = el.getAttribute('target') || '_self';
			window.open(url, target || '_self');
		}
    });
});

// Contact & subscribe form
const form = document.querySelector('.js-gtm-form form');
if (form) {
	const data = JSON.parse(form.dataset.fuiForm);

	if (data.formHandle === 'contact') {
		form.addEventListener('onAfterFormieSubmit', () => {
		    window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'send_enquiry' });
		});
	}

	if (data.formHandle === 'signUp') {
		form.addEventListener('onAfterFormieSubmit', () => {
		    window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'email_sign_up' });
		});
	}	
}

// Commerce

const ecommerceItems = document.querySelectorAll('.js-gtm-ecommerce');

Array.from(ecommerceItems).forEach( el => {
    el.addEventListener('click', (e) => {
    	e.preventDefault();

    	const url = el.href || null;
    	const dataset = { ...el.dataset };

    	window.dataLayer = window.dataLayer || [];
    	window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
  			event: 'select_item',
  			ecommerce: {
    			items: [dataset]
  			}
		});

		if (url) {
			window.location.href = url;
		}
    });
});

// Shop page
const shop = document.querySelector('.js-shop');
if (shop) {

	const ecommerceItems = shop.querySelectorAll('.js-gtm-ecommerce');
	const items = [];

	Array.from(ecommerceItems).forEach( el => {
		let dataset = { ...el.dataset };

    	dataset = Object.fromEntries(
  			Object.entries(dataset).map(([key, value]) => [key, value.toLowerCase()])
		);

		items.push(dataset);
	});

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items
		}
	});
}