const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');
const selectorParser = require('postcss-selector-parser');

module.exports = {
    content: ['./templates/**/*.twig', './vue/**/*.vue'],
    darkMode: 'is-dark-mode',
    theme: {
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            white: '#fff',
            black: {
                DEFAULT: '#1C1C1C',
                grey: '#333333',
                medium: '#2C2C2C',
                1: '#1C1C1C',
                2: '#000000'
            },
            neutral: {
                DEFAULT: '#f9f9f9',
                300: '#d4d4d4'
            },
            grey: {
                DEFAULT: '#e6e6e6',
                light: '#eeeeee',
                disabled: '#F8F8F8',
                medium: '#F3F3F3',
                1: '#F1F1F1',
                2: '#484848'
            },
            green: '#1E824C',
            red: '#DF3207',
            midgrey: '#999999',
            error: '#FFF1F1'
        },

        screens: {
            xs: '520px',
            sm: '640px',
            md: '768px',
            lg: '1080px',
            xl: '1280px',
            xxl: '1600px'
        },

        // fontSize: {
        //     xxxs: '.625rem',
        //     xxs: '.6875rem',
        //     s: '.8125rem',
        //     104: '104px',
        //     90: '90px',
        //     48: '48px',
        //     42: '42px',
        //     34: '34px',
        //     30: '30px',
        //     28: '28px',
        //     26: '26px',
        //     24: '24px',
        //     22: '22px',
        //     18: '18px',
        //     16: '16px',
        //     14: '14px',
        //     12: '12px',
        //     10: '10px'
        // },

        letterSpacing: {
            tight: '-0.02em',
            normal: '-0.015em',
            wide: '-0.01em',
            wider: '0.01em',
            widest: '0.02em',
            heading: '0.005em'
        },

        lineHeight: {
            loose: 1.85,
            relaxed: 1.666,
            normal: 1.5,
            snug: 1.33,
            tight: 1.25,
            small: 1.11,
            none: 1,
            negative: 0.85
        },

        extend: {
            fontFamily: {
                sans: ['FoundersGrotesk', ...defaultTheme.fontFamily.sans]
            },

            borderRadius: {
                DEFAULT: '5rem'
            },

            zIndex: {
                negative: '-1',
                positive: '1'
            },

            width: {
                'quarter-screen': '25vw',
                'half-screen': '50vw'
            },

            height: {
                'full-spaced': 'calc(100vh - 5rem)'
            },

            spacing: {
                screen: '100vw',
                'quarter-screen': '25vw',
                'half-screen': '50vw'
            },

            fontSize: {
                xxxs: '.625rem',
                xxs: '.6875rem',
                s: '.8125rem',
                120: '120px',
                104: '104px',
                90: '90px',
                86: '86px',
                72: '72px',
                60: '60px',
                54: '54px',
                48: '48px',
                42: '42px',
                34: '34px',
                32: '32px',
                30: '30px',
                28: '28px',
                26: '26px',
                24: '24px',
                22: '22px',
                20: '20px',
                19: '19px',
                18: '18px',
                16: '16px',
                15: '15px',
                14: '14px',
                12: '12px',
                11: '11px',
                10: '10px'
            },

            keyframes: {
                fade: {
                    '0%': { opacity: 0 },
                    '40%': { opacity: 0 },
                    '60%': { opacity: 1 },
                    '90%': { opacity: 1 },
                    '100%': { opacity: 0 },
                }
            },
        }
    },

    corePlugins: {
        container: false,
        aspectRatio: false
    },

    variants: {
        textColor: ['dark', 'secondary', 'responsive', 'hover', 'focus'],
        fontSize: ['dark', 'secondary', 'responsive', 'hover', 'focus'],
        backgroundColor: ['dark', 'secondary', 'responsive', 'hover', 'focus']
    },

    plugins: [
        require('@tailwindcss/aspect-ratio'),
        require('@tailwindcss/typography'),
        require('autoprefixer'),
        plugin(function ({ addVariant, prefix }) {
            // Add module dark variant
            addVariant('dark', ({ modifySelectors, separator }) => {
                modifySelectors(({ selector }) => {
                    return selectorParser((selectors) => {
                        selectors.walkClasses((sel) => {
                            sel.value = `dark${separator}${sel.value}`;
                            sel.parent.insertBefore(sel, selectorParser().astSync(prefix('.module-scheme-dark ')));
                        });
                    }).processSync(selector);
                });
            });
            // Add module layout variant
            addVariant('secondary', ({ modifySelectors, separator }) => {
                modifySelectors(({ selector }) => {
                    return selectorParser((selectors) => {
                        selectors.walkClasses((sel) => {
                            sel.value = `secondary${separator}${sel.value}`;
                            sel.parent.insertBefore(sel, selectorParser().astSync(prefix('.module-type-secondary ')));
                        });
                    }).processSync(selector);
                });
            });
        }),
        ({ matchUtilities, theme }) => {  
            matchUtilities({
                'aspect': (value) => ({
                    '@supports (aspect-ratio: 1 / 1)': {
                        aspectRatio: value,
                    },
                    '@supports not (aspect-ratio: 1 / 1)': {
                        '&::before': {
                            content: '""',
                            float: 'left',
                            paddingTop: `calc(100% / (${value}))`,
                        },
                        '&::after': {
                            clear: 'left',
                            content: '""',
                            display: 'block',
                        }
                    },
                 }),
            },
            { values: theme('aspectRatio') }
        )},
    ]
};
